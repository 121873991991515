import React, { ReactElement } from "react";
import { useScrollPosition } from "../../hooks/useScollPosition";
import "./ImageWithQuote.scss";

export const ImageWithQuote: React.FC<{
  quote: ReactElement;
  image: string;
  orientation: "left" | "rigth";
}> = ({ quote, image, orientation }) => {
  const { relativePosition } = useScrollPosition();
  let imageProps: { left?: number; right?: number } = { left: 100 };
  let quoteProps: { left?: number; right?: number } = { right: 100 };

  if (orientation === "rigth") {
    imageProps = { right: 100 };
    quoteProps = { left: 100 };
  }

  return (
    <div className="ImageWithQuote__container">
      <img
        src={image}
        style={{
          position: "absolute",
          top: 50,
          height: "75%",
          opacity:
            relativePosition < 0.25
              ? 1
              : Math.max(0.35, 1 - relativePosition + 0.25),
          transform: `scale(${
            relativePosition < 0.25
              ? 1
              : Math.max(0.75, 1 - relativePosition + 0.25)
          })`,
          ...imageProps,
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom:
            relativePosition < 0.35
              ? -120
              : `${Math.min(35, -120 + 155 * (relativePosition + 0.35))}%`,
          ...quoteProps,
        }}
      >
        {quote}
      </div>
    </div>
  );
};
