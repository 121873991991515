import { Scroller } from "./components/Scroller/Scroller";
import { Section } from "./components/Section/Section";
import { useScrollPosition } from "./hooks/useScollPosition";
import "./App.scss";
import { MainTitle } from "./components/MainTitle/MainTitle";
import { ImageWithQuote } from "./components/ImageWithQuote/ImageWithQuote";
import { NeverFired } from "./components/NeverFired/NeverFired";
import { useEffect, useRef } from "react";
import { DaysWithout } from "./components/DaysWithout/DaysWithout";

const FirstSection: React.FC<{ className: string }> = ({ className }) => {
  const { relativePosition } = useScrollPosition();

  const style: React.CSSProperties = {
    opacity: relativePosition,
  };

  return (
    <div className={`section ${className}`}>
      <div style={style} className="white-backdrop"></div>
      <h1>0xFAFAFA</h1>
    </div>
  );
};

function App() {
  // const songRef = useRef<HTMLAudioElement | null>(null);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (songRef.current) {
  //       songRef.current.play();
  //     }
  //   }, 1500);
  // }, [songRef]);

  return (
    <>
      <Scroller className="scroller-container">
        <Section name="first-section" duration={1000}>
          <MainTitle />
        </Section>
        <Section className="small" name="second-section" duration={1500}>
          <ImageWithQuote
            orientation="left"
            image="/first-image.png"
            quote={
              <div style={{ color: "white" }}>
                <h1>
                  "You miss 100% of the shots you don't take.
                  <br /> - Wayne Gretzky"
                </h1>
                - Lucio Bassani
              </div>
            }
          />
        </Section>
        <Section className="small" name="third-section" duration={1500}>
          <ImageWithQuote
            orientation="rigth"
            image="/third-image.png"
            quote={
              <div style={{ color: "white" }}>
                <h1>"Guga chupame bien la pija!"</h1>- Lucio Bassani
              </div>
            }
          />
        </Section>
        <Section className="small" name="fourth-section" duration={1500}>
          <ImageWithQuote
            orientation="left"
            image="/fourth-image.png"
            quote={
              <div style={{ color: "white" }}>
                <h1>"Ojala me despidan"</h1>- Lucio Bassani
              </div>
            }
          />
        </Section>
        <Section name="fifth-section" duration={1500}>
          <NeverFired />
        </Section>
        <Section name="sixth-section" duration={1500}>
          <DaysWithout />
        </Section>
      </Scroller>
    </>
  );
}

export default App;
