import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import "./DaysWithout.scss";

dayjs.extend(duration);
const GONE_DATE = dayjs("2023-02-06T00:00:00.00Z");

export const DaysWithout: React.FC = () => {
  const [current, setCurrent] = useState(dayjs());

  useEffect(() => {
    setInterval(() => {
      setCurrent(dayjs());
    }, 1000);
  }, []);

  return (
    <div className="DaysWithout__container">
      <h1>
        {dayjs.duration(current.diff(GONE_DATE)).format("YYYY-MM-DD HH:mm:ss")}
      </h1>
      <h2>SIN LUCIO</h2>
    </div>
  );
};
