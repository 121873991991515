import React from "react";
import { useScrollPosition } from "../../hooks/useScollPosition";
import "./MainTitle.scss";

export const MainTitle: React.FC = () => {
  const { relativePosition } = useScrollPosition();

  const style: React.CSSProperties = {
    opacity: 1 - relativePosition,
  };

  return (
    <div
      className="MainTitle__container"
      style={{
        backgroundImage: 'url("/main-photo.png")',
      }}
    >
      <div style={style}>
        <h1>GRACIAS LUCIO POR TODOS ESTOS AÑOS</h1>
        <h3>Tus compañeros de Let's Bit te recordaran por siempre</h3>
        <audio id="song" src="/i-will-remember.mp3" loop controls autoPlay />
      </div>
    </div>
  );
};
