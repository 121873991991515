import React from "react";
import { useScrollPosition } from "../../hooks/useScollPosition";
import "./NeverFired.scss";

export const NeverFired: React.FC = () => {
  const { relativePosition } = useScrollPosition();

  return (
    <div className="NeverFired__container">
      <h1>NUNCA FUE DESPEDIDO</h1>
    </div>
  );
};
