import React, { PropsWithChildren, useEffect } from "react";
import { ScrollerProvider } from "../../context/ScrollerContext";
import { useScrollerContext } from "../../hooks/useScrollerContext";
import "./Scroller.scss";

interface ScrollerProps {
  className?: string;
}

export const Scroller: React.FC<PropsWithChildren<ScrollerProps>> = ({
  children,
  className = "",
}) => {
  return (
    <ScrollerProvider>
      <SectionsContainer className={className}>{children}</SectionsContainer>
    </ScrollerProvider>
  );
};

interface SectionsContainerProps {
  className: string;
}

const SectionsContainer: React.FC<
  PropsWithChildren<SectionsContainerProps>
> = ({ children, className }) => {
  const { getHeight, scrollerRef } = useScrollerContext();
  const height: number = getHeight();

  return (
    <div
      onScroll={() => {
        console.log("asdasd");
      }}
      className={`fafascroller-container ${className}`}
      style={{ height }}
    >
      {children}
    </div>
  );
};
